import clsx from 'clsx';
import { BodyText, MetaText } from 'components/common/Typography';
import { useTranslation } from 'hooks/useContentful/useTranslation';
import { useHomepageFeatures } from 'hooks/useHomepageFeatures';
import { DollarIcon } from 'icons/monochrome/DollarIcon';
import { StarIcon } from 'icons/monochrome/StarIcon';
import { RoutePlannerIcon } from 'icons/routePlanner/RoutePlannerIcon';
import { makeStyles } from 'lib/makeStyles';
import React from 'react';
import { SerializedComponentItem } from 'types/contentful/SerializedComponentItem';

type Props = {
  entry: SerializedComponentItem;
  active?: boolean;
};

const iconMap = {
  'nearby-amenities': RoutePlannerIcon,
  'search-capabilities': StarIcon,
  'forecast-predictions': DollarIcon
};

export const useStyles = makeStyles({
  root: ['flex flex-row justify-start items-center', 'mb-4 md:mb-16', 'focus-default'],
  outerWrapper: [
    'flex justify-center items-center',
    'border rounded-full',
    'w-10 h-10 shadow-sm transition-all'
  ],
  isSelectedIcon: 'border-primaryBorderActive bg-tmBlue text-white',
  isNotSelectedIcon: 'border-primaryBorder bg-white text-primaryIcon',
  innerWrapper: 'max-w-sm flex-col justify-start items-start md:flex',
  isSelectedText: [
    'absolute md:relative left-16 top-0 md:left-0 flex',
    ' p-4 rounded-lg md:p-0',
    'bg-white md:bg-transparent',
    'shadow-lg md:shadow-none'
  ],
  primaryText: 'text-left',
  secondaryText: 'text-left',
  icon: ['w-4 h-4']
});

export const Feature = React.memo(({ entry }: Props) => {
  const [, setSelected, isSelected] = useHomepageFeatures(entry.id);
  const { t } = useTranslation();
  const styles = useStyles();

  const Icon = iconMap[entry.icon];

  if (!Icon) {
    return <></>;
  }

  return (
    <button
      className={styles.root}
      aria-label={`${t('TOGGLE')} ${entry.icon}`}
      onClick={setSelected}>
      <div className="mr-4">
        <div
          className={clsx(
            styles.outerWrapper,
            isSelected ? styles.isSelectedIcon : styles.isNotSelectedIcon
          )}>
          <Icon className={styles.icon} />
        </div>
      </div>
      <div
        className={clsx(
          styles.innerWrapper,
          isSelected && styles.isSelectedText,
          !isSelected && 'hidden'
        )}>
        <BodyText as="h2" bold color="primary" active={isSelected} className={styles.primaryText}>
          {entry.primaryText}
        </BodyText>
        <MetaText as="h3" thin color="secondary" className={styles.secondaryText}>
          {entry.secondaryText}
        </MetaText>
      </div>
    </button>
  );
});

Feature.displayName = 'Feature';
