'use client';
import { BodyText, HeadingText } from 'components/common/Typography';
import { Asset } from 'components/contentful/Asset';
import { Container } from 'components/layout/Container';
import { ClearanceIcon } from 'icons/roadSigns/ClearanceIcon';
import { DeclineIcon } from 'icons/roadSigns/DeclineIcon';
import { NoTruckAccessIcon } from 'icons/roadSigns/NoTruckAccessIcon';
import { makeStyles } from 'lib/makeStyles';
import { UIComponentProps } from 'types/contentful';

import { Translation } from 'components/common/Translation';
import { FeatureBullets } from '../../contentful/FeatureBullet/FeatureBullets';
import { Bullet } from './Bullet';

type Props = UIComponentProps;

const useStyles = makeStyles({
  outerWrapper: [
    'py-16 md:py-24',
    'bg-neutralBackground bg-image-left-border bg-pos-20 bg-repeat-y md:bg-image-homepage-section-journey md:bg-no-repeat md:bg-center'
  ],
  container: ['relative'],
  headingWrapper: [
    'grid grid-cols-1 space-y-6 md:grid-cols-2 md:space-x-6 md:space-y-0 px-1 md:px-4'
  ],
  heading: ['block mb-6 md:(max-w-xl)'],
  subHeading: ['md:max-w-xl'],
  contentWrapper: ['md:hidden relative pt-16 pb-36'],
  assetWrapper: ['absolute inset-0 overflow-hidden px-4 pt-4 md:hidden'],
  asset: ['absolute right-0 bottom-0 w-3/4 h-auto object-contain object-bottom'],
  gradient: ['absolute inset-0 bg-gradient-to-b from-neutralBackground'],
  bulletsWrapper: ['relative z-20 space-y-8'],
  declineIconWrapper: ['flex flex-row relative'],
  declineContentWrapper: ['bg-white rounded-md w-48 absolute left-24 shadow-lg'],
  declinePrimaryText: ['bg-yellow-500 rounded-t-md text-sm text-center py-1'],
  declineSecondaryText: ['text-xs p-2'],
  flexRow: ['flex flex-row'],
  whiteBackground: ['bg-white'],
  bulletsGrid: [
    'grid grid-cols-1 md:grid-cols-12',
    'px-1 md:px-4',
    'space-y-4 md:space-x-6 md:space-y-6 md:mt-24'
  ],
  gridColumns: ['col-span-3 hidden md:block'],
  asset1: ['max-w-none w-full'],
  featureBulletsGrid: ['grid grid-cols-1 md:grid-cols-2 col-span-9 items-center', 'md:gap-x-4']
});

const HomepageSectionJourney: React.FC<Props> = ({ entry, items }) => {
  const [imageItem] = items.get('Image');
  const featureBullets = items.get('FeatureBullet');
  const styles = useStyles();

  const [heading, subheading] = entry.richTextSections;

  return (
    <div className={styles.outerWrapper}>
      <Container className={styles.container}>
        <div className={styles.headingWrapper}>
          <div>
            <HeadingText size="MD" as="h2" className={styles.heading}>
              {heading.primaryText}
            </HeadingText>
            <BodyText className={styles.subHeading}>{subheading.primaryText}</BodyText>
          </div>
        </div>
        <div className={styles.contentWrapper}>
          <div className={styles.assetWrapper}>
            <Asset entry={imageItem} className={styles.asset} />
            <div className={styles.gradient} />
          </div>
          <div className={styles.bulletsWrapper}>
            <div className={styles.declineIconWrapper}>
              <Bullet>
                <DeclineIcon size="4xl" />
              </Bullet>
              <div className={styles.declineContentWrapper}>
                <div className={styles.declinePrimaryText}>{<Translation textKey="CAUTION" />}</div>
                <div className={styles.declineSecondaryText}>
                  {<Translation textKey="MESSAGE_DECLINE" />}
                </div>
              </div>
            </div>
            <div className={styles.flexRow}>
              <Bullet>
                <NoTruckAccessIcon size="4xl" />
              </Bullet>
              <div className={styles.whiteBackground} />
            </div>
            <div className={styles.flexRow}>
              <Bullet>
                <ClearanceIcon size="4xl" />
              </Bullet>
              <div className={styles.whiteBackground} />
            </div>
          </div>
        </div>
        <div className={styles.bulletsGrid}>
          <div className={styles.gridColumns}>
            <Asset entry={imageItem} className={styles.asset1} />
          </div>
          <FeatureBullets
            className={styles.featureBulletsGrid}
            featureBullets={featureBullets}
            as="h3"
          />
        </div>
      </Container>
    </div>
  );
};

export { HomepageSectionJourney };
